<template>
  <div class="derivative-mobile" v-if="isMobile">
    <img src="../assets/img/banner_home.png" class="img-banner">
    <div class="main-content">
      <div class="row-me tab-div">
        <div class="column-me center-all left-div">
          <div class="title1">衍生品</div>
          <div class="title2">DERIVATIVE</div>
        </div>
        <div class="right-div row-me row-center flex-wrap flex1">
        </div>
      </div>
      <div class="row-me center-all margin-top60">
        <img src="../assets/img/title_line.png" class="img-line">
        <div class="column-me row-center margin-left10 margin-right10">
          <div class="title">红尾狐品牌视觉及衍生产品</div>
        </div>
        <img src="../assets/img/title_line.png" class="img-line">
      </div>
      <div class="row-me center-all">
        <div class="subtitle">VISION AND DERIVATIVES</div>
      </div>
      <div class="content">
        未来红尾狐的品牌视觉将不断升级，与时俱进，希望从少儿运动竞技到少儿健康生活，从而让红尾狐走进消费者的心中，
        致力于打造少儿运动竞技·体适能素质教育创业孵化专业平台，成为行业领导者，希望红尾狐能够陪伴每一位孩子快乐运动，快乐成长！
      </div>
      <img src="../assets/img/ysp.png" class="img-ysp" draggable="false">
    </div>
  </div>


  <div class="derivative" v-else>
    <img src="../assets/img/banner_home.png" class="img-banner">
    <div class="main-content">
      <div class="row-me tab-div">
        <div class="row-me center-all left-div">
          <div class="title1">衍生品</div>
          <div class="title2 margin-left20">DERIVATIVE</div>
        </div>
        <div class="right-div row-me row-center flex-wrap flex-end flex1">
        </div>
      </div>
      <div class="row-me center-all margin-top90">
        <img src="../assets/img/title_line.png" class="img-line">
        <div class="column-me row-center margin-left60 margin-right60">
          <div class="title">红尾狐品牌视觉及衍生产品</div>
          <div class="subtitle">VISION AND DERIVATIVES</div>
        </div>
        <img src="../assets/img/title_line.png" class="img-line">
      </div>
      <div class="content">
        未来红尾狐的品牌视觉将不断升级，与时俱进，希望从少儿运动竞技到少儿健康生活，从而让红尾狐走进消费者的心中，
        致力于打造少儿运动竞技·体适能素质教育创业孵化专业平台，成为行业领导者，希望红尾狐能够陪伴每一位孩子快乐运动，快乐成长！
      </div>
      <img src="../assets/img/ysp.png" class="img-ysp" draggable="false">
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  data() {
    return {
      isMobile: false,
    }
  },
  // html加载完成之前
  created() {

  },
  // html加载完成后执行。
  mounted() {
    this.isMobile = sessionStorage.getItem('mobile') === '1'
  },
  // 事件方法执行
  methods: {},
  // 计算属性
  computed: {},
  // 侦听器
  watch: {},
  activated() {

  }
}
</script>

<style lang="scss" scoped>
.derivative-mobile {
  .img-banner {
    width: 100%;
    object-fit: cover;
    pointer-events: none;
  }

  .main-content {
    padding: 20px;

    .tab-div {
      background: #F4F4F4;
      border-radius: 10px;

      .left-div {
        width: 130px;
        background: #000000;
        border-radius: 10px 0px 0px 10px;
        padding: 10px 0;

        .title1 {
          font-size: 23px;
          color: #FFFFFF;
        }

        .title2 {
          font-size: 15px;
          color: #FFF10C;
          margin-top: 10px;
        }
      }

      .right-div {

        .item-title {
          margin-bottom: 10px;
        }
      }

      .title0 {
        font-size: 18px;
        color: #999999;
        padding: 0 10px;
        cursor: pointer;

        &:hover {
          font-weight: bold;
        }

        &-active {
          color: #666666;
          font-weight: bold;
        }
      }

      .tab-line {
        width: 3px;
        height: 20px;
        background: #999999;
        border-radius: 10px;
      }
    }

    .img-line {
      width: 80px;
    }

    .title {
      font-size: 23px;
      color: #000000;
      letter-spacing: 2px;
      text-align: center;
      margin-bottom: 10px;
    }

    .subtitle {
      font-size: 18px;
      color: #666666;
    }

    .content {
      width: 100%;
      font-size: 22px;
      color: #333333;
      line-height: 42px;
      margin-top: 54px;
    }

    .img-ysp {
      width: 100%;
      object-fit: fill;
      margin-top: 30px;
    }
  }
}

.derivative {
  .img-banner {
    width: 100%;
    object-fit: cover;
    pointer-events: none;
  }

  .main-content {
    padding: 80px 100px;

    .tab-div {
      height: 100px;
      background: #F4F4F4;
      border-radius: 10px;

      .left-div {
        width: 330px;
        background: #000000;
        border-radius: 10px 0px 0px 10px;

        .title1 {
          font-size: 23px;
          color: #FFFFFF;
        }

        .title2 {
          font-size: 15px;
          color: #FFF10C;
          margin-bottom: -15px;
        }
      }

      .right-div {

        .item-title {
          line-height: 45px;
        }
      }

      .title0 {
        font-size: 26px;
        color: #999999;
        margin-left: 30px;
        text-align: center;
        cursor: pointer;

        &:hover {
          font-weight: bold;
        }

        &-active {
          color: #666666;
          font-weight: bold;
        }
      }

      .tab-line {
        width: 3px;
        height: 30px;
        margin-left: 30px;
        background: #999999;
        border-radius: 10px;
      }
    }

    .img-line {
      width: 145px;
      object-fit: scale-down;
      margin-bottom: 10px;
    }

    .title {
      font-size: 48px;
      color: #000000;
      letter-spacing: 5px;
      text-align: center;
    }

    .subtitle {
      font-size: 24px;
      color: #666666;
    }

    .content {
      width: 100%;
      font-size: 24px;
      color: #333333;
      line-height: 42px;
      margin-top: 54px;
    }

    .img-ysp {
      width: 100%;
      object-fit: fill;
      margin-top: 50px;
    }
  }
}
</style>
